import { Text } from "@mantine/core";
import { PropsWithChildren } from "react";

export const HeaderCell = ({ children }: PropsWithChildren) => {
  return (
    <div>
      <Text className="font-semibold text-start uppercase" size="xs">
        {children}
      </Text>
    </div>
  );
};
