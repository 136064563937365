import type { Cell, Column, Header } from "@tanstack/react-table";
import omit from "lodash/omit";
import type { ReactElement, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import { HeaderCell } from "@/components/manage-locations/cells";
import type {
  CellMetaWithSimpleOnChange,
  LocationCellContext,
  LocationDraftField,
  LocationDraftKey,
  RowMeta,
} from "@/types/manage-locations";
import {
  type CleanLocationDraftForServer,
  propertiesToCleanFromLocationDraft,
  type ScreenerLocation,
  type ScreenerLocationDraft,
} from "@/types/screener-location";
import type { Nullable } from "@/types/utils";
import { numberIsTruthyOrZero } from "@/utils/helpers";

// Just to avoid magic strings throughout.
export const MANAGE_LOCATIONS_COLUMN_FIELDS = {
  actions: "actions",
  atsJobId: "atsJobId",
  includeWages: "includeWages",
  locationName: "locationName",
  maxPayHourly: "maxPayHourly",
  minPayHourly: "minPayHourly",
  sessionCount: "sessionCount",
  shareLinkSlug: "shareLinkSlug",
  status: "status",
} as const;

export const headerCellWithContent = (content: ReactNode) => () => (
  <HeaderCell>{content}</HeaderCell>
);

export const getTableMetaAndLocationId = <TValue,>({
  row: { original: location },
  table: {
    options: { meta },
  },
}: LocationCellContext<TValue>): RowMeta => {
  const manageLocationsMeta = meta?.manageLocations;

  if (!manageLocationsMeta) {
    throw new TypeError(
      "Attempted to resolve Manage Locations Table meta but it was not defined.",
    );
  }

  const isEditing = location.id === manageLocationsMeta.editingLocationId;
  return {
    ...manageLocationsMeta,
    // New locations are always a draft until being saved
    draft: location.isNew ? location : manageLocationsMeta.draft,
    isEditing,
    locationId: location.id,
  };
};

type BuildCell<
  Field extends LocationDraftKey,
  TValue,
  Meta extends RowMeta | CellMetaWithSimpleOnChange<Field>,
> = (info: LocationCellContext<TValue>, meta: Meta) => ReactElement;

// Utility function for including all table metadata as well as the current
// row's ID.
export const withMetaAndLocationId =
  <TValue,>(buildCell: BuildCell<LocationDraftKey, TValue, RowMeta>) =>
  (info: LocationCellContext<TValue>) =>
    buildCell(info, getTableMetaAndLocationId(info));

// Wrapper for `withMetaAndLocationId` that replaces the change function with
// a simple (value: TValue) => void for ease of use within components that don't
// need access to multiple properties.
export const withSimpleOnCellChange = <Field extends LocationDraftKey>(
  field: Field,
  buildCell: BuildCell<
    Field,
    LocationDraftField<Field>,
    CellMetaWithSimpleOnChange<Field>
  >,
) =>
  withMetaAndLocationId(
    (info: LocationCellContext<LocationDraftField<Field>>, meta: RowMeta) => {
      const { original: location } = info.row;

      if (location.isNew) {
        const onChange = (value: LocationDraftField<Field>) =>
          meta.onCellChange({ [field]: value }, location.id);

        return buildCell(info, {
          ...meta,
          // Fall back to row value for new locations rather than an existing
          // location's draft
          draftValue: location[field],
          isEditing: true,
          onChange,
        });
      }

      const onChange = (value: LocationDraftField<Field>) => {
        meta.onCellChange({ [field]: value });
      };
      return buildCell(info, {
        ...meta,
        draftValue: meta.draft?.[field],
        onChange,
      });
    },
  );

export const convertLocationToDraft = ({
  atsJobId,
  id,
  locationName,
  maxPayHourly,
  minPayHourly,
  sessionCount,
  shareLinkSlug,
  status,
}: ScreenerLocation): ScreenerLocationDraft => {
  return {
    atsJobId,
    id,
    includeWages: includesWages({ maxPayHourly, minPayHourly }),
    isNew: false,
    locationName,
    maxPayHourly,
    minPayHourly,
    sessionCount,
    shareLinkSlug,
    status,
  };
};

export const cleanDraftOnlyProperties = (
  draft: ScreenerLocationDraft,
): CleanLocationDraftForServer => {
  const cleaned = omit(draft, ...propertiesToCleanFromLocationDraft);
  const { maxPayHourly, minPayHourly, shareLinkSlug } = cleaned;

  return {
    ...cleaned,
    maxPayHourly: numberIsTruthyOrZero(maxPayHourly) ? maxPayHourly : null,
    minPayHourly: numberIsTruthyOrZero(minPayHourly) ? minPayHourly : null,
    shareLinkSlug: shareLinkSlug?.trim() ?? null,
  };
};

export const includesWages = (
  location: Nullable<{ maxPayHourly?: number; minPayHourly?: number }>,
) =>
  !!(
    location &&
    (numberIsTruthyOrZero(location.maxPayHourly) ||
      numberIsTruthyOrZero(location.minPayHourly))
  );

const wagesAreValid = (location: Nullable<ScreenerLocationDraft>) => {
  if (!location) {
    return false;
  }
  const { includeWages, maxPayHourly, minPayHourly } = location;

  // Wages explicitly excluded by user
  if (!includeWages) {
    return true;
  }

  // Both wages are either missing, zero, or negative, invalid
  if (
    (!maxPayHourly || maxPayHourly <= 0) &&
    (!minPayHourly || minPayHourly <= 0)
  ) {
    return false;
  }

  // Min > Max, invalid
  if (minPayHourly && maxPayHourly && minPayHourly > maxPayHourly) {
    return false;
  }

  return true;
};

export const locationDraftValidator = (
  draft: Nullable<ScreenerLocationDraft>,
) => ({
  locationName: !!draft?.locationName,
  wageRange: wagesAreValid(draft),
  wages: !draft?.includeWages || includesWages(draft),
});

export const addCommonAnimationClassNames = (
  currentClassNames: string[] = [],
) =>
  currentClassNames.concat([
    "ease-out",
    "duration-300",
    "motion-reduce:transition-none",
  ]);

export const getInputCellAnimationClassNames = (isEditing: boolean) => {
  const staticClassNames = addCommonAnimationClassNames([
    "disabled:bg-transparent",
    "disabled:cursor-default",
    "focus:border-uw-purple",
    "outline-none",
    "transition-[border-color,padding-left]",
  ]);

  return twMerge(
    staticClassNames,
    isEditing
      ? ["border-uw-gray-2", "pl-3.5"]
      : [
          "border-uw-gray-2/0",
          "pl-0",
          "placeholder:text-uw-gray-9",
          "disabled:cursor-text",
          "disabled:text-uw-gray-9",
          "disabled:opacity-100",
        ],
  );
};

export const getSharedManageLocationsCellClassNames = (
  cell:
    | Cell<ScreenerLocationDraft, unknown>
    | Header<ScreenerLocationDraft, unknown>,
) =>
  twMerge([
    ["border-uw-gray-2", "border-b", "pl-8", "py-2.5", "text-start"],
    cell.column.id === MANAGE_LOCATIONS_COLUMN_FIELDS.locationName && [
      "bg-white",
      "pl-[18px]",
      "pr-8",
      "shadow-[inset_-1px_0px_0px_0px_#d9d9d9d9]",
      "sticky",
      "min-w-[270px]",
      "z-[1]",
    ],
    cell.column.id === MANAGE_LOCATIONS_COLUMN_FIELDS.actions &&
      "min-w-[186px] pl-0",
    // Prevent size change on edit when toggle switch becomes visible
    cell.column.id === MANAGE_LOCATIONS_COLUMN_FIELDS.includeWages && [
      "min-w-[175px]",
    ],
    cell.column.id === MANAGE_LOCATIONS_COLUMN_FIELDS.status && [
      "min-w-[136px]",
    ],
  ]);

export const getManageLocationsPinnedColumnStyle = (
  column: Column<ScreenerLocationDraft>,
) => {
  const pinned = column.getIsPinned();

  if (pinned) {
    return {
      left: `${column.getStart(pinned)}px`,
    };
  }

  return {};
};
