import {
  useMutation,
  useQueryClient,
  type UseQueryOptions,
} from "@tanstack/react-query";

import { useUserContext } from "@/context/user.context";
import {
  bulkHighlightScreenerSession,
  bulkSetSessionViewedStatus,
  getCandidateScreenings,
  getScreeningsByLocation,
  highlightScreenerSession,
  markSessionViewed,
  reprocessScreenerSession,
} from "@/app/api/screeners";
import { SCREENER_LOCATION_QUERY_KEYS } from "@/hooks/queries/screener-locations.queries";
import { useAuthedQuery } from "@/hooks/queries/use-authed-query.hook";
import type { CandidateResponse } from "@/types/screeners";
import type { Nullable } from "@/types/utils";

const SCREENINGS_QUERY_KEY_BASE = "screening";
export const SCREENINGS_QUERY_KEYS = {
  base: [SCREENINGS_QUERY_KEY_BASE] as const,
  candidate: (email: string, sessionId?: string) => [
    ...SCREENINGS_QUERY_KEYS.base,
    "candidate",
    email,
    sessionId,
  ],
  location: (locationId: string) => [
    ...SCREENINGS_QUERY_KEYS.base,
    "screener_location",
    locationId,
  ],
};

export const useScreeningsByLocationQuery = (
  screenerLocationId: string,
  config: Partial<UseQueryOptions<CandidateResponse>> = {},
) => {
  return useAuthedQuery<CandidateResponse>({
    queryFn: () => getScreeningsByLocation(screenerLocationId),
    queryKey: SCREENINGS_QUERY_KEYS.location(screenerLocationId),
    ...config,
  });
};

export const useCandidateScreeningsQuery = (
  email = "",
  sessionId = "",
  config: Partial<UseQueryOptions<CandidateResponse>> = {},
) => {
  return useAuthedQuery<CandidateResponse>({
    queryFn: () => getCandidateScreenings(email, sessionId),
    queryKey: SCREENINGS_QUERY_KEYS.candidate(email, sessionId),
    ...config,
    enabled: !!email && config.enabled,
  });
};

export const useHighlightScreenerSessionQuery = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: ({ id, value }: { id: string; value: Nullable<string> }) =>
      highlightScreenerSession(id, value),
    onSuccess: () =>
      client.invalidateQueries({ queryKey: SCREENINGS_QUERY_KEYS.base }),
  });
};

export const useBulkHighlightQuery = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: ({
      sessionIds,
      color,
    }: {
      sessionIds: string[];
      color: Nullable<string>;
    }) => bulkHighlightScreenerSession(sessionIds, color),
    onSuccess: () =>
      client.invalidateQueries({ queryKey: SCREENINGS_QUERY_KEYS.base }),
  });
};

export const useBulkSetViewedStatusQuery = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: ({
      sessionIds,
      viewedAt,
    }: {
      sessionIds: string[];
      viewedAt: Nullable<string>;
    }) => bulkSetSessionViewedStatus(sessionIds, viewedAt),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: SCREENINGS_QUERY_KEYS.base });
    },
  });
};

export const useReprocessScreenerSessionQuery = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: (sessionId: string) => reprocessScreenerSession(sessionId),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: SCREENINGS_QUERY_KEYS.base });
    },
  });
};

export const useMarkSessionViewedQuery = () => {
  const client = useQueryClient();
  const { user } = useUserContext();

  return useMutation({
    mutationFn: (sessionId: string) => {
      // Avoid marking client records as viewed if they are viewed by Upwage
      // internal users.
      if (user?.internal) {
        return Promise.resolve();
      }

      return markSessionViewed(sessionId);
    },
    onSuccess: () => {
      client.invalidateQueries({ queryKey: SCREENINGS_QUERY_KEYS.base });
      // Invalidate locations as well so the table view updates unviewed count
      client.invalidateQueries({ queryKey: SCREENER_LOCATION_QUERY_KEYS.base });
    },
  });
};
