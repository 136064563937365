import { Flex, Switch } from "@mantine/core";
import { useMemo } from "react";
import { twMerge } from "tailwind-merge";

import { useMutationVariables } from "@/hooks/useMutationVariables";
import { SCREENER_LOCATIONS_MUTATION_KEYS } from "@/hooks/queries/screener-locations.queries";
import type {
  CommonEditableManageLocationCellProps,
  UpdateLocationMutationVariables,
} from "@/types/manage-locations";
import { ScreenerLocationStatus } from "@/types/screener-location";
import type { Maybe } from "@/types/utils";
import { addCommonAnimationClassNames } from "@/utils/manage-locations";

interface ToggleInputCellProps
  extends CommonEditableManageLocationCellProps<boolean> {
  // The "Status" variation of this cell is always interactive, regardless of
  // editing state
  alwaysEnabled?: boolean;
  offText: string;
  onText: string;
}

export const ToggleInputCell = ({
  alwaysEnabled = false,
  draftValue,
  isEditing,
  locationId,
  offText,
  onChange,
  onText,
  value,
}: ToggleInputCellProps) => {
  // If we have a mutation in progress for this row this hook will return the
  // value of the in-progress update
  const optimisticState = useMutationVariables<
    UpdateLocationMutationVariables,
    Maybe<boolean>
  >(SCREENER_LOCATIONS_MUTATION_KEYS.updateScreenerLocation(), (variables) => {
    const rowMutation = variables.find(
      ({ locationId: id }) => id === locationId,
    );

    if (!rowMutation) {
      return;
    }

    return rowMutation.screenerLocation.status === ScreenerLocationStatus.Live;
  });

  // Render the optimistic update if it exists, render the draft value if we are
  // editing, fall back to true value
  const renderValue = useMemo(
    () => optimisticState ?? (isEditing ? draftValue : value),
    [draftValue, isEditing, optimisticState, value],
  );

  const label = useMemo(
    () => (renderValue ? onText : offText),
    [offText, onText, renderValue],
  );
  const disabled = !(isEditing || alwaysEnabled);

  return (
    <Flex>
      <Switch
        checked={renderValue}
        classNames={{
          label: "text-uw-gray-9",
          thumb: twMerge(
            addCommonAnimationClassNames([
              "bg-white",
              "transition-[background-color,border-color,inset,opacity]",
            ]),
            disabled && "opacity-0",
          ),
          track: twMerge(
            addCommonAnimationClassNames(["transition-[min-width,width]"]),
            disabled && ["border-none", "min-w-0", "w-0"],
          ),
        }}
        color="mint-green.8"
        disabled={disabled}
        label={label}
        onChange={(e) => onChange(e.currentTarget.checked)}
      />
    </Flex>
  );
};
