import { faArrowRotateLeft } from "@fortawesome/pro-solid-svg-icons/faArrowRotateLeft";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faEdit } from "@fortawesome/pro-solid-svg-icons/faEdit";
import { faXmark } from "@fortawesome/pro-solid-svg-icons/faXmark";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip } from "@mantine/core";
import { useParams } from "next/navigation";
import { useCallback, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";

import { useTrackAnalytics } from "@/hooks/analytics.hooks";
import type { RowMeta } from "@/types/manage-locations";
import {
  ScreenerLocationDraft,
  ScreenerLocationStatus,
} from "@/types/screener-location";
import { locationDraftValidator } from "@/utils/manage-locations";

enum RowState {
  Editing,
  New,
  Viewing,
}

const getSharedButtonProps = (isWide = false) => ({
  classNames: { section: "ml-[5px]" },
  w: isWide ? 90 : 80,
  size: "xs",
  variant: "transparent",
});

interface ActionsCellProps extends RowMeta {
  location: ScreenerLocationDraft;
  onCancelClick: () => void;
  onEditClick: () => void;
  onRemoveClick: (draft: ScreenerLocationDraft) => Promise<void>;
  onSaveClick: () => Promise<unknown>;
  removeAllowed: boolean;
}

export const ActionsCell = ({
  draft,
  isEditing,
  location,
  locationId,
  onCancelClick,
  onEditClick,
  onRemoveClick,
  onSaveClick,
  removeAllowed,
}: ActionsCellProps) => {
  const { id: screenerId } = useParams<{ id: string }>();
  const track = useTrackAnalytics({ screener_id: screenerId });
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [removeIsLoading, setRemoveIsLoading] = useState(false);
  const isEditingOtherRow = !!(draft && !isEditing);
  const rowState = useMemo(() => {
    if (draft?.isNew) {
      return RowState.New;
    }

    if (isEditing) {
      return RowState.Editing;
    }

    return RowState.Viewing;
  }, [draft, isEditing]);

  const getDisabledClassNames = (variant: "filled" | "transparent") =>
    twMerge(
      ["disabled:opacity-50", "disabled:text-uw-purple"],
      variant === "filled"
        ? ["disabled:bg-uw-purple", "disabled:text-white"]
        : "disabled:bg-transparent",
    );

  const saveDisabledTooltip = useMemo(() => {
    const { locationName, wageRange, wages } = locationDraftValidator(draft);

    if (!locationName && !wages) {
      return "Add a location name and valid wages (if applicable) before saving";
    }

    if (!locationName) {
      return "Add a location name before saving";
    }

    if (!wages) {
      return "Add at least one wage (or mark wages as not included) before saving";
    }

    if (!wageRange) {
      return "Maximum wage must be higher than minimum wage";
    }
  }, [draft]);

  const removeDisabledTooltip = useMemo(() => {
    const { sessionCount, status } = location;

    if (sessionCount) {
      return "Locations with responses can’t be removed";
    }

    if (status === ScreenerLocationStatus.Live) {
      return "Locations must be paused to be removed";
    }
  }, [location]);

  const handleSave = useCallback(() => {
    setSaveIsLoading(true);

    if (draft?.isNew) {
      track("Save New Location Clicked");
    } else {
      track("Save Edited Location Clicked", { location_id: locationId });
    }

    onSaveClick().finally(() => setSaveIsLoading(false));
  }, [draft, locationId, onSaveClick, track]);

  const handleRemove = useCallback(() => {
    // Remove new draft
    if (draft?.isNew) {
      track("Remove New Location Clicked");

      return onRemoveClick(draft);
    }

    // Remove existing location
    if (removeAllowed) {
      setRemoveIsLoading(true);
      track("Remove Existing Location Clicked", { location_id: locationId });

      onRemoveClick(location).finally(() => setRemoveIsLoading(false));
    }
  }, [draft, location, locationId, onRemoveClick, removeAllowed, track]);

  return (
    <div className="pr-3.5">
      {rowState === RowState.New || rowState === RowState.Viewing ? (
        <Tooltip disabled={removeAllowed} label={removeDisabledTooltip}>
          <Button
            {...getSharedButtonProps(true)}
            className={getDisabledClassNames("transparent")}
            disabled={!removeAllowed}
            loading={removeIsLoading}
            onClick={handleRemove}
            rightSection={<FontAwesomeIcon icon={faXmark} />}
          >
            Remove
          </Button>
        </Tooltip>
      ) : (
        <Button
          {...getSharedButtonProps(true)}
          onClick={() => {
            track("Cancel Edit Location Clicked", { location_id: locationId });
            onCancelClick();
          }}
          rightSection={<FontAwesomeIcon icon={faArrowRotateLeft} />}
        >
          Cancel
        </Button>
      )}
      {rowState === RowState.Viewing ? (
        <Tooltip
          disabled={!isEditingOtherRow}
          label="You can only edit one location at a time"
        >
          <Button
            {...getSharedButtonProps()}
            className={getDisabledClassNames("transparent")}
            disabled={isEditingOtherRow}
            onClick={() => {
              track("Edit Screener Location Clicked", {
                location_id: locationId,
              });
              onEditClick();
            }}
            rightSection={<FontAwesomeIcon icon={faEdit} />}
          >
            Edit
          </Button>
        </Tooltip>
      ) : (
        <Tooltip disabled={!saveDisabledTooltip} label={saveDisabledTooltip}>
          <Button
            {...getSharedButtonProps()}
            className={getDisabledClassNames("filled")}
            disabled={!!saveDisabledTooltip}
            loading={saveIsLoading}
            onClick={handleSave}
            rightSection={<FontAwesomeIcon icon={faCheck} />}
            variant="filled"
          >
            Save
          </Button>
        </Tooltip>
      )}
    </div>
  );
};
