import type { DefaultError } from "@tanstack/query-core";
import {
  type QueryFunction,
  type SkipToken,
  useQuery,
  type UseQueryOptions,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { useMemo } from "react";

import { useUserContext } from "@/context/user.context";

export const useAuthedQuery = <
  QueryFnData,
  TError = DefaultError,
  ReturnData = QueryFnData,
>({
  queryFn,
  ...config
}: UseQueryOptions<QueryFnData, TError, ReturnData>) => {
  const { user } = useUserContext();
  const enabled = useMemo(() => {
    if (!user) {
      return false;
    }

    return config.enabled;
  }, [config, user]);
  // We wrap the query function to allow New Relic to capture errors that it
  // otherwise misses.
  const wrappedQueryFn: QueryFunction<QueryFnData> | SkipToken | undefined =
    useMemo(() => {
      if (typeof queryFn === "function") {
        return (...args) => {
          const response = queryFn(...args);

          if (response instanceof Promise) {
            return response.catch((err: AxiosError) => {
              window.newrelic?.noticeError(err, {
                code: err.code,
                stack: err.stack,
                status: err.status,
                url: err.request.responseURL,
              });

              throw err;
            });
          }

          return response;
        };
      }

      return queryFn;
    }, [queryFn]);

  return useQuery({
    ...config,
    enabled,
    queryFn: wrappedQueryFn,
  });
};
