import { NumberInput } from "@mantine/core";
import { usePrevious } from "@mantine/hooks";
import { useEffect } from "react";

import type { CommonEditableManageLocationCellProps } from "@/types/manage-locations";
import type { Maybe } from "@/types/utils";
import { getInputCellAnimationClassNames } from "@/utils/manage-locations";

interface WageCellProps
  extends CommonEditableManageLocationCellProps<Maybe<number>> {
  includeWages: boolean;
}

export const WageCell = ({
  draft,
  draftValue,
  includeWages,
  isEditing,
  onChange,
  value,
}: WageCellProps) => {
  const previousIncludeWages = usePrevious(includeWages);
  const editingWithIncludeWages = isEditing && !!draft?.includeWages;

  // Handle include wages toggle change
  useEffect(() => {
    if (isEditing && !includeWages && previousIncludeWages) {
      onChange(undefined);
    }
  }, [includeWages, isEditing, onChange, previousIncludeWages]);

  return (
    <NumberInput
      allowNegative={false}
      classNames={{
        input: getInputCellAnimationClassNames(editingWithIncludeWages),
      }}
      decimalScale={2}
      disabled={!editingWithIncludeWages}
      fixedDecimalScale
      hideControls
      onValueChange={({ floatValue }) => onChange(floatValue)}
      placeholder={editingWithIncludeWages ? "$0.00" : "—"}
      prefix="$"
      // Passing `undefined` can cause issues with clearing the input. Fall back
      // to empty string instead
      value={(isEditing ? draftValue : value) ?? ""}
      w={125}
    />
  );
};
