import { MutationFilters } from "@tanstack/query-core";
import { Mutation, useMutationState } from "@tanstack/react-query";

/**
 * Opted for full JSDoc comment here as this use case is new to the codebase.
 * This function uses a mutationKey (which is not required on mutations and may
 * need to be added. Works the same as query keys) to find pending mutations and
 * expose their variables, typically for optimistic UI updates.
 * @template Variables - The variables the mutation accepts (the properties
 *  passed to the mutation function `mutationFn: ({ imAVariable, meToo }) =>`)
 * @template R - The return value of your selector function, can be anything.
 * @param {string[]}  mutationKey - The mutation key of the mutation you are
 *  interested in. May need to be added as it is not required by default.
 * @param {(variables: Variables[]) => R} selector - Given function for
 *  selecting and parsing of the found variables.
 * @param {Partial<MutationFilters>} [filters={}] - Optional filters object for
 *  extending/overwriting configuration
 * @returns {R} Return value of given selector function
 */
export const useMutationVariables = <Variables, R>(
  mutationKey: string[],
  selector: (variables: Variables[]) => R,
  filters: Partial<MutationFilters> = {},
): R => {
  const variables = useMutationState<Variables>({
    filters: {
      mutationKey,
      status: "pending",
      ...filters,
    },
    select: (mutation: Mutation) => mutation.state.variables as Variables,
  });

  return selector(variables);
};
