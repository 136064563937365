import type { DefaultError } from "@tanstack/query-core";
import {
  useMutation,
  useQueryClient,
  type UseQueryOptions,
} from "@tanstack/react-query";

import {
  assignUserToLocation,
  bulkSetScreenerLocationStatus,
  createScreenerLocation,
  deleteScreenerLocation,
  getAssignedScreenerLocations,
  getScreenerLocationsByScreenerId,
  getTableScreenerLocationsByScreener,
  updateScreenerLocation,
} from "@/app/api/screener-locations";
import { useAuthedQuery } from "@/hooks/queries/use-authed-query.hook";
import type { UpdateLocationMutationVariables } from "@/types/manage-locations";
import type {
  AllLocationsResponse,
  CleanLocationDraftForServer,
  IndividualScreenerLocationStatusType,
  ScreenerLocation,
  ScreenerLocationDraft,
  ScreenerLocationsTableResponse,
} from "@/types/screener-location";
import type { Nullable } from "@/types/utils";
import { convertLocationToDraft } from "@/utils/manage-locations";

const SCREENER_LOCATIONS_QUERY_KEY_BASE = "screener_location";
export const SCREENER_LOCATION_QUERY_KEYS = {
  base: [SCREENER_LOCATIONS_QUERY_KEY_BASE] as const,
  tableDataByScreenerId: (screenerId: string) => [
    ...SCREENER_LOCATION_QUERY_KEYS.base,
    "table",
    screenerId,
  ],
  byScreenerId: (screenerId: string) => [
    ...SCREENER_LOCATION_QUERY_KEYS.base,
    screenerId,
  ],
  assigned: () => [...SCREENER_LOCATION_QUERY_KEYS.base, "assigned"],
};
export const SCREENER_LOCATIONS_MUTATION_KEYS = {
  updateScreenerLocation: () => [
    ...SCREENER_LOCATION_QUERY_KEYS.base,
    "updateScreenerLocation",
  ],
};

//////////////////////////////////////
//// Screener Location Table Data ////
//////////////////////////////////////

export const useTableScreenerLocationsByScreenerQuery = (
  screenerId: string,
  config: Partial<UseQueryOptions<AllLocationsResponse>> = {},
) => {
  return useAuthedQuery({
    queryKey: SCREENER_LOCATION_QUERY_KEYS.tableDataByScreenerId(screenerId),
    queryFn: () => getTableScreenerLocationsByScreener(screenerId),
    ...config,
  });
};

export const useAssignedScreenerLocationsQuery = (
  config: Partial<UseQueryOptions<ScreenerLocationsTableResponse>> = {},
) => {
  return useAuthedQuery({
    queryFn: getAssignedScreenerLocations,
    queryKey: SCREENER_LOCATION_QUERY_KEYS.assigned(),
    ...config,
  });
};

export const useAssignUserToLocationMutation = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: ({
      locationId,
      userId,
    }: {
      locationId: string;
      userId: Nullable<string>;
    }) => assignUserToLocation(locationId, userId),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: SCREENER_LOCATION_QUERY_KEYS.base });
    },
  });
};

///////////////////////////////////
//// Manage Screener Locations ////
///////////////////////////////////

export const useScreenerLocationByScreenerIdQuery = (
  screenerId: string,
  // More complex types than normal here because we are fetching from a function
  // that returns the ScreenerLocation type, but then we transform to the
  // ScreenerLocationDraft type in `select`
  config: Partial<
    UseQueryOptions<
      ScreenerLocation[],
      DefaultError,
      { drafts: ScreenerLocationDraft[]; locations: ScreenerLocation[] }
    >
  > = {},
) => {
  return useAuthedQuery<
    ScreenerLocation[],
    DefaultError,
    { drafts: ScreenerLocationDraft[]; locations: ScreenerLocation[] }
  >({
    queryKey: SCREENER_LOCATION_QUERY_KEYS.byScreenerId(screenerId),
    queryFn: () => getScreenerLocationsByScreenerId(screenerId),
    select: (locations) => ({
      drafts: locations.map(convertLocationToDraft),
      locations,
    }),
    ...config,
  });
};

export const useUpdateScreenerLocationMutation = (screenerId: string) => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: ({
      locationId,
      screenerLocation,
    }: UpdateLocationMutationVariables) =>
      updateScreenerLocation(locationId, screenerLocation),
    mutationKey: SCREENER_LOCATIONS_MUTATION_KEYS.updateScreenerLocation(),
    onSuccess: (updatedLocation) => {
      client.setQueryData(
        SCREENER_LOCATION_QUERY_KEYS.byScreenerId(screenerId),
        (locations: ScreenerLocation[]) => {
          const updatedIndex = locations.findIndex(
            ({ id }) => id === updatedLocation.id,
          );

          return updatedIndex === -1
            ? locations.slice()
            : locations.toSpliced(updatedIndex, 1, updatedLocation);
        },
      );
    },
  });
};

export const useCreateScreenerLocationMutation = (screenerId: string) => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: ({
      newLocation,
    }: {
      newLocation: CleanLocationDraftForServer;
    }) => createScreenerLocation(newLocation, screenerId),
    onSuccess: (newLocation) => {
      client.setQueryData(
        SCREENER_LOCATION_QUERY_KEYS.byScreenerId(screenerId),
        (previousLocations: ScreenerLocation[]) => [
          newLocation,
          ...previousLocations,
        ],
      );
    },
  });
};

export const useBulkSetScreenerLocationStatusMutation = (
  screenerId: string,
) => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: ({
      locationIds,
      status,
    }: {
      locationIds: string[];
      status: IndividualScreenerLocationStatusType;
    }) => bulkSetScreenerLocationStatus(locationIds, status),
    onSuccess: (updatedLocations) =>
      client.setQueryData(
        SCREENER_LOCATION_QUERY_KEYS.byScreenerId(screenerId),
        updatedLocations,
      ),
  });
};

export const useDeleteScreenerLocationMutation = (screenerId: string) => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: ({ locationId }: { locationId: string }) =>
      deleteScreenerLocation(locationId),
    onSuccess: (locations, { locationId }) =>
      client.setQueryData(
        SCREENER_LOCATION_QUERY_KEYS.byScreenerId(screenerId),
        (oldLocations: ScreenerLocation[]) =>
          oldLocations.filter(({ id }) => id !== locationId),
      ),
  });
};
